// Import necessary components and hooks
import React, { useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { useNavigate } from "react-router-dom";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase";

const SignUpPage = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    phone: "",
    hearAbout: "",
    agreedTerms: false,
    stayInLoop: false,
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.agreedTerms) {
      alert("Please agree to the terms and conditions to proceed.");
      return;
    }

    try {
      await addDoc(collection(db, "users"), {
        ...formData,
        properties: [],
        contacts: [],
        createdAt: new Date(), // Track creation timestamp
      });
      alert("Account created successfully!");
    } catch (error) {
      console.error("Error adding document: ", error);
      alert("Error creating account. Please try again.");
    }
  };

  return (
    <div>
      <Header />

      <div className="max-w-5xl mx-auto min-h-screen flex flex-col lg:flex-row items-center justify-center px-4 py-10">
        {/* Slogan Section */}
        <div className="w-full lg:w-1/2 flex flex-col items-center lg:items-start text-center lg:text-left mb-8 lg:mb-0">
          <h1 className="text-4xl lg:text-5xl font-bold text-blue-600 mb-6">
            Create your Girilang Account Today
          </h1>
          <p className="text-lg text-gray-700">
            Effortlessly manage, optimise, and grow your property portfolio with
            confidence with Girilang.
          </p>
        </div>

        {/* Form Section */}
        <div className="w-full lg:w-1/2 max-w-md bg-white p-8">
          <form onSubmit={handleSubmit}>
            {/* First Name Field */}
            <label className="block mb-2 font-semibold">First Name*</label>
            <input
              type="text"
              name="firstName"
              className="w-full p-2 mb-4 border border-gray-300 rounded-2xl"
              value={formData.firstName}
              onChange={handleChange}
              required
            />

            {/* Last Name Field */}
            <label className="block mb-2 font-semibold">Last Name*</label>
            <input
              type="text"
              name="lastName"
              className="w-full p-2 mb-4 border border-gray-300 rounded-2xl"
              value={formData.lastName}
              onChange={handleChange}
              required
            />

            {/* Email Field */}
            <label className="block mb-2 font-semibold">Email Address*</label>
            <input
              type="email"
              name="email"
              className="w-full p-2 mb-4 border border-gray-300 rounded-2xl"
              value={formData.email}
              onChange={handleChange}
              required
            />

            {/* Company Field */}
            <label className="block mb-2 font-semibold">Company*</label>
            <input
              type="text"
              name="company"
              className="w-full p-2 mb-4 border border-gray-300 rounded-2xl"
              value={formData.company}
              onChange={handleChange}
              required
            />

            {/* Phone Field */}
            <label className="block mb-2 font-semibold">Phone Number*</label>
            <input
              type="text"
              name="phone"
              className="w-full p-2 mb-4 border border-gray-300 rounded-2xl"
              value={formData.phone}
              onChange={handleChange}
              required
            />

            {/* How Did You Hear About Us Field */}
            <label className="block mb-2 font-semibold">
              How did you hear about Girilang?
            </label>
            <input
              type="text"
              name="hearAbout"
              className="w-full p-2 mb-4 border border-gray-300 rounded-2xl"
              value={formData.hearAbout}
              onChange={handleChange}
            />

            {/* Terms & Conditions Checkbox */}
            <div className="mb-4">
              <input
                type="checkbox"
                name="agreedTerms"
                checked={formData.agreedTerms}
                onChange={handleChange}
                required
              />
              <label className="ml-2">
                I have read and agreed to the Girilang terms & conditions *
              </label>
            </div>

            {/* Stay in the Loop Checkbox */}
            <div className="mb-4 cursor-pointer">
              <input
                type="checkbox"
                name="stayInLoop"
                checked={formData.stayInLoop}
                onChange={handleChange}
              />
              <label className="ml-2">
                Stay in the loop for the latest news
              </label>
            </div>

            <button
              type="submit"
              className="w-full font-semibold bg-blue-600 text-white p-2 rounded-2xl hover:bg-blue-700"
            >
              Create Account
            </button>
          </form>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default SignUpPage;
