import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Import Router components
import "./styles/tailwind.css";
import App from "./App";
import Signup from "./pages/signup"; // Import the Signup component
import PrivacyPolicy from "./components/privacy-policy"; // Import Privacy Policy
import TermsOfService from "./components/terms-of-service"; // Import Terms of Service

import About from "./components/about"; // Import About Us page

// Ensure that you are targeting the 'root' element
const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} /> {/* Main App route */}
        <Route path="/signup" element={<Signup />} /> {/* Signup route */}
        {/* Signup route */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />{" "}
        {/* Privacy Policy */}
        <Route path="/terms-of-service" element={<TermsOfService />} />{" "}
        {/* Terms of Service */}
        <Route path="/about" element={<About />} /> {/* About Us */}
      </Routes>
    </Router>
  </React.StrictMode>
);
