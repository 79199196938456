import React from "react";
import Header from "../components/header";
import Footer from "../components/footer"; // Import the Footer component

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen flex flex-col">
      {" "}
      <Header />
      <main className="flex-grow">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <h1 className="text-3xl font-bold text-gray-900 mb-4">About Us</h1>
          <p className="text-gray-600 mb-4">
            At Girilang, we are committed to protecting the privacy of our
            users. This Privacy Policy outlines how we collect, use, and
            safeguard your personal information in compliance with Australian
            privacy laws, including the Australian Privacy Act 1988 and the NSW
            Privacy and Personal Information Protection Act 1998.
          </p>
        </div>
        <Footer />
      </main>
    </div>
  );
};

export default PrivacyPolicy;
