import React from "react";

const Footer = () => {
  return (
    <footer className="py-8 font-medium">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 gap-8">
          <div>
            <h2 className="text-base font-bold mb-4">Girilang</h2>
          </div>
          {/* Company */}
          <div>
            <h2 className="text-base font-semibold mb-4">Company</h2>
            <ul className="space-y-2 text-sm">
              <li>
                <a href="/about" className="hover:underline">
                  About us
                </a>
              </li>

              <li>
                <a href="/privacy-policy" className="hover:underline">
                  Privacy policy
                </a>
              </li>
              <li>
                <a href="/terms-of-service" className="hover:underline">
                  Terms of Service
                </a>
              </li>
            </ul>
          </div>

          {/* Knowledge Center */}
          <div>
            <h2 className="text-base font-semibold mb-4">Knowledge Center</h2>
            <ul className="space-y-2 text-sm">
              <li>
                <a href="/service-status" className="hover:underline">
                  Service Status
                </a>
              </li>
              <li>
                <a href="/blog" className="hover:underline">
                  Blog
                </a>
              </li>
              <li>
                <a href="/help-center" className="hover:underline">
                  Help Center
                </a>
              </li>
            </ul>
          </div>

          {/* Customer Portal */}
          <div>
            <h2 className="text-base font-semibold mb-4">Customer Portal</h2>
            <ul className="space-y-2 text-sm">
              <li>
                <a href="/work-management" className="hover:underline">
                  Work Management
                </a>
              </li>
              <li>
                <a href="/flexible-crm" className="hover:underline">
                  Flexible CRM
                </a>
              </li>
              <li>
                <a href="/process-tracker" className="hover:underline">
                  Process Tracker
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="text-center text-sm text-zinc-400 mt-8">
          &copy; {new Date().getFullYear()} Girilang. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
