import { useState } from "react";
import { motion } from "framer-motion"; // Import Framer Motion

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-white font-medium">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <a href="../">
              <h1 className="text-2xl font-bold text-zinc-900">Girilang</h1>
            </a>
          </div>
          <div className="hidden text-base md:flex space-x-6 items-center">
            <a href="/" className="text-zinc-800 hover:text-zinc-600">
              Home
            </a>
            <a className="text-zinc-800 hover:text-zinc-600">Features</a>
            <a className="text-zinc-800 hover:text-zinc-600">Pricing</a>
            <a className="text-zinc-800 hover:text-zinc-600">Contact</a>
            <a
              href="https://app.girilang.com/ "
              className="border-solid border-2 py-1.5 px-3 rounded-xl hover:bg-zinc-100 transition duration-300"
              target="_blank"
              rel="noopener noreferrer"
            >
              Open App
            </a>
            <a
              href="https://girilang.com/ "
              className="bg-black text-white py-1.5 px-3 rounded-xl hover:bg-zinc-800 transition duration-300"
              target="_blank"
              rel="noopener noreferrer"
            >
              Get a demo
            </a>
          </div>
          {/* Hamburger button */}
          <div className="flex items-center md:hidden">
            <button
              onClick={toggleMenu}
              className="text-zinc-800 hover:text-zinc-600 focus:outline-none"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={
                    isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"
                  }
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <a
              href="/"
              className="block px-3 py-2 rounded-md text-base font-medium text-zinc-800 hover:text-zinc-600 hover:bg-zinc-200"
            >
              Home
            </a>
            <a
              href="/features"
              className="block px-3 py-2 rounded-md text-base font-medium text-zinc-800 hover:text-zinc-600 hover:bg-zinc-200"
            >
              Features
            </a>
            <a
              href="/pricing"
              className="block px-3 py-2 rounded-md text-base font-medium text-zinc-800 hover:text-zinc-600 hover:bg-zinc-200"
            >
              Pricing
            </a>
            <a
              href="/contact"
              className="block px-3 py-2 rounded-md text-base font-medium text-zinc-800 hover:text-zinc-600 hover:bg-zinc-200"
            >
              Contact
            </a>

            <a
              href="https://app.girilang.com/ "
              className="block px-3 py-2 border-solid border-2 rounded-xl hover:bg-zinc-100 transition duration-300"
              rel="noopener noreferrer"
            >
              Open App
            </a>
            <a
              href="/signup"
              className=" block px-3 py-2 bg-black text-white rounded-xl hover:bg-zinc-800 transition duration-300"
              rel="noopener noreferrer"
            >
              Get a demo
            </a>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
