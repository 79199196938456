// src/components/ui/AnimatedText.js
import React from "react";
import { TypeAnimation } from "react-type-animation";

const AnimatedText = () => {
  return (
    <p className="text-4xl font-semibold mt-6 leading-relaxed">
      Effortlessly <br />
      <TypeAnimation
        sequence={[
          "Lease",
          2000,
          "Maintain",
          2000,
          "Connect",
          2000,
          "Grow",
          2000,
        ]}
        wrapper="span"
        cursor={true}
        repeat={Infinity}
        className="text-[#0065ff] border-2 border-dashed border-[#0065ff] px-4 rounded-lg"
      />{" "}
      <br />
      all from one platform
    </p>
  );
};

export default AnimatedText;
