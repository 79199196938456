// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCs_0DeBsuKAD5UFZJl1UKxxeyvDBOBYMI",
  authDomain: "girilang-client.firebaseapp.com",
  projectId: "girilang-client",
  storageBucket: "girilang-client.appspot.com",
  messagingSenderId: "1031198462402",
  appId: "1:1031198462402:web:1e3155806e3ddb3114065b",
  measurementId: "G-0X4S1Q4F5X",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
