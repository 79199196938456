import React from "react";
import Header from "../components/header";
import Footer from "../components/footer"; // Import the Footer component

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen flex flex-col">
      {" "}
      <Header />
      <main className="flex-grow">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <h1 className="text-3xl font-bold text-gray-900 mb-4">
            Privacy Policy
          </h1>
          <p className="text-gray-600 mb-4">
            At Girilang, we are committed to protecting the privacy of our
            users. This Privacy Policy outlines how we collect, use, and
            safeguard your personal information in compliance with Australian
            privacy laws, including the Australian Privacy Act 1988 and the NSW
            Privacy and Personal Information Protection Act 1998.
          </p>
          <h3 className="text-base font-semibold text-gray-900 mb-2">
            1. Information We Collect
          </h3>
          <p className="text-gray-600 mb-4">
            We collect the following personal information from users:
            <ul className="list-disc list-inside ml-4 mt-2">
              <li>Names</li>
              <li>Email addresses</li>
              <li>Phone numbers</li>
              <li>Addresses</li>
              <li>
                Billing information (managed by a third-party payment processor)
              </li>
              <li>IP addresses</li>
              <li>Uploaded user images</li>
              <li>Property data</li>
              <li>Tenant data</li>
            </ul>
          </p>
          <h3 className="text-base font-semibold text-gray-900 mb-2">
            2. Purpose of Data Collection
          </h3>
          <p className="text-gray-600 mb-4">
            We collect personal information for the following purposes:
            <ul className="list-disc list-inside ml-4 mt-2">
              <li>Account creation and management</li>
              <li>Providing and improving services</li>
              <li>Billing and payment processing</li>
              <li>Enhancing the user experience on our platform</li>
            </ul>
          </p>
          <h3 className="text-base font-semibold text-gray-900 mb-2">
            3. Data Storage
          </h3>
          <p className="text-gray-600 mb-4">
            All personal information is securely stored on Firebase, a
            third-party cloud service provider.
          </p>
          <h3 className="text-base font-semibold text-gray-900 mb-2">
            4. Data Retention
          </h3>
          <p className="text-gray-600 mb-4">
            We retain personal information for the duration of an active account
            and for up to 24 months after account inactivity to allow for
            reactivation or support purposes. After this period, data is either
            deleted or de-identified. Billing and financial records are retained
            for 5 years to comply with Australian tax laws. Uploaded data, such
            as images and documents, are deleted upon account termination or
            when no longer required for service provision.
          </p>
          <h3 className="text-base font-semibold text-gray-900 mb-2">
            5. Cookies and Tracking Technologies
          </h3>
          <p className="text-gray-600 mb-4">
            We use cookies and similar technologies to enhance user experience
            and collect information about how users interact with our platform.
            These include:
            <ul className="list-disc list-inside ml-4 mt-2">
              <li>
                <strong>Essential Cookies:</strong> Necessary for core
                functionality, such as secure login and managing user sessions.
              </li>
              <li>
                <strong>Analytics Cookies:</strong> Used to track anonymised
                user interactions for internal analytics.
              </li>
              <li>
                <strong>Advertising and Marketing Cookies:</strong> May be used
                to show personalised content or targeted ads.
              </li>
              <li>
                <strong>Performance Cookies:</strong> Collect data on platform
                performance to optimise user experience.
              </li>
            </ul>
          </p>
          <h3 className="text-base font-semibold text-gray-900 mb-2">
            6. Data Transfers
          </h3>
          <p className="text-gray-600 mb-4">
            All data is stored and processed within Australia. We do not
            transfer or process personal information outside of Australia.
          </p>
          <h3 className="text-base font-semibold text-gray-900 mb-2">
            7. Security Measures
          </h3>
          <p className="text-gray-600 mb-4">
            We implement the following measures to protect your personal data:
            <ul className="list-disc list-inside ml-4 mt-2">
              <li>
                <strong>Encryption:</strong> All sensitive information is
                encrypted both in transit and at rest.
              </li>
              <li>
                <strong>Access Controls:</strong> Strict access controls with
                role-based permissions and multi-factor authentication (MFA).
              </li>
              <li>
                <strong>Data Security Audits:</strong> Regular audits and
                external penetration testing.
              </li>
              <li>
                <strong>Regular Security Updates:</strong> Ongoing system
                updates and vulnerability monitoring.
              </li>
              <li>
                <strong>Data Backup and Recovery:</strong> Comprehensive backup
                procedures to ensure data restoration in case of an incident.
              </li>
              <li>
                <strong>Compliance:</strong> Our security practices comply with
                relevant Australian and international data protection
                regulations.
              </li>
            </ul>
          </p>
          <h3 className="text-base font-semibold text-gray-900 mb-2">
            8. User Rights
          </h3>
          <p className="text-gray-600 mb-4">
            While we do not offer the option to manage or opt-out of data usage
            preferences, users may request access to, correction of, or deletion
            of their personal data at any time by contacting us.
          </p>
          <h3 className="text-base font-semibold text-gray-900 mb-2">
            9. Complaints Handling Procedure
          </h3>
          <p className="text-gray-600 mb-4">
            If you believe your privacy has been breached or have concerns about
            how we handle your personal information, you can submit a complaint
            by contacting us at [Insert Contact Details]. We take complaints
            seriously and will investigate and respond to your concerns
            promptly.
          </p>
          <p className="text-gray-600 mb-4">
            If you are not satisfied with our response or believe we are not
            handling your personal information in accordance with the law, you
            can lodge a complaint with the{" "}
            <strong>
              Office of the Australian Information Commissioner (OAIC)
            </strong>
            . For more information about how to lodge a complaint, please visit
            the OAIC website at:
            <a
              href="https://www.oaic.gov.au"
              className="text-blue-600 hover:text-blue-800 ml-1"
            >
              https://www.oaic.gov.au
            </a>
          </p>
          <h3 className="text-base font-semibold text-gray-900 mb-2">
            10. Changes to This Policy
          </h3>
          <p className="text-gray-600 mb-4">
            We reserve the right to modify this Privacy Policy at any time.
            Users will be notified of any significant changes, and the updated
            policy will be posted on our website.
          </p>
          <h3 className="text-base font-semibold text-gray-900 mb-2">
            11. Contact Details
          </h3>
          <p className="text-gray-600">
            If you have any questions or concerns about this Privacy Policy,
            please contact us at:
          </p>
          <p className="text-gray-600 mb-4">
            <strong>Girilang</strong>
            <br />
            Email: privacy@girilang.com
          </p>
        </div>
        <Footer />
      </main>
    </div>
  );
};

export default PrivacyPolicy;
