import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion"; // Import motion from framer-motion
import Header from "./components/header";
import Footer from "./components/footer";
import ReactGA from "react-ga4";
import AnimatedText from "./components/ui/AnimatedText";

function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize("G-RPNVP3KJ82");
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  // Animation settings for the buttons
  const buttonAnimation = {
    whileHover: { scale: 1.05 },
    whileTap: { scale: 0.95 },
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 20,
    },
  };

  return (
    <div className="min-h-screen flex flex-col leading-relaxed">
      <Header />
      <main className="flex-grow">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <AnimatedText />

          <p className="text-zinc-600 font-medium min-w-96 w-3/6">
            Leverage advanced AI, data analytics, and automation to stay ahead
            making smarter decisions faster, without the hassle.
          </p>
          <div className="my-14 flex flex-col sm:flex-row gap-4 sm:gap-5 space-y-5 sm:space-y-0">
            {/* Get Started Button with animation */}
            <motion.div {...buttonAnimation}>
              <Link
                to="/signup"
                className="bg-[#0066ff] text-white font-semibold py-4 px-8 rounded-3xl hover:bg-[#0055cc] transition duration-300 text-center sm:w-auto"
              >
                Try Girilang for free
              </Link>
            </motion.div>

            {/* Find Out More Button with animation */}
            <motion.div {...buttonAnimation}>
              <Link
                to="/find-out-more"
                className="bg-[#defcd9] text-black font-semibold py-4 px-8 rounded-3xl hover:bg-[#c6eebf] transition duration-300 text-center sm:w-auto"
              >
                Find out more
              </Link>
            </motion.div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default App;
